import CalendarDay from "../components/CalendarDay";
import OptionPicker from "../components/OptionPicker";
import { useEffect, useContext, useState, useRef } from "react";
import { PostRequest } from "../api/requests/postRequest";
import { ReservationEntryContext } from "../context/reservationEntryContext";
import { ReservationNavigationContext } from "../context/reservationNavigationContext";
import { formatToDayMonthYear } from "../helpers/useDateHelpers";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { ReservationDataContext } from "../context/reservationDataContext";
import { TIME_BY_ACTIVITY_STEP } from "../constants/reservationSteps";

export default function TimeByActivity() {
  const { t, i18n } = useTranslation();
  const {
    maxDaysInAdvance,
    steps,
    activities,
    locationName,
    companySlug,
    hasCompanyOtherLocations,
  } = useContext(ReservationDataContext);

  const notes = steps.find((x) => x.id === TIME_BY_ACTIVITY_STEP).notes;
  const navigate = useNavigate();

  const currentDate = new Date();
  const maxDate = new Date();
  maxDate.setDate(currentDate.getDate() + +maxDaysInAdvance);

  currentDate.setHours(0, 0, 0, 0);
  const { nextStep } = useContext(ReservationNavigationContext);
  const { reservation, onReservationChange } = useContext(
    ReservationEntryContext
  );
  const [timeSlots, setTimeSlots] = useState("");
  const [weekTimeSlots, setWeekTimeSlots] = useState([]);
  const [daysOfWeek, setDaysOfWeek] = useState([]);
  const selectedDate = useRef(reservation.startDate);
  const [targetDayInMonth, setTargetDayInMonth] = useState(0);
  const [targetMonthName, setTargetMonthName] = useState("");
  const [targetYear, setTargetYear] = useState();

  const handleTimeSlotChange = (e) => {
    var newEntry = reservation.entries;
    var wantedActivity = activities.filter((x) => x.id === e.target.dataset.activity);

    if (wantedActivity.length === 0) return;
    newEntry[0].locationActivityId = e.target.dataset.activity;
    newEntry[0].durationInMinutes = wantedActivity[0].durationInMinutes;
    onReservationChange({ startTime: e.target.value, entries: newEntry });
    nextStep();
  };

  const handleDateChange = (e) => {
    const date = new Date(e.target.value);
    const dayInMonth = date.getDate();
    setTargetDayInMonth(dayInMonth);
    setTargetMonthName(date.toLocaleString(i18n.language, { month: "long" }));
    setTargetYear(date.getFullYear());
    selectedDate.current = date;
    setTimeSlots(
      weekTimeSlots.find((x) => x.dayInMonth === dayInMonth).timeslots
    );
  };

  useEffect(() => {
    setTargetMonthName(() =>
      selectedDate.current.toLocaleString(i18n.language, { month: "long" })
    );
  }, [i18n.language]);

  useEffect(() => {
    const resDate = new Date(reservation.startDate);
    const dayInMonth = resDate.getUTCDate();
    setTargetDayInMonth(dayInMonth);
    setTargetMonthName(
      resDate.toLocaleString(i18n.language, { month: "long" })
    );
    setTargetYear(resDate.getFullYear());

    const loadTimeSlots = async () => {
      if (!reservation.locationId) return;

      try {
        await PostRequest(
          `/api/v1/locations/${reservation.locationId}/time-slots`,
          reservation
        ).then((response) => {
          if (response) {
            setWeekTimeSlots(response);
            setTimeSlots(
              response.find((x) => x.dayInMonth === dayInMonth).timeslots
            );
          }
        });
      } catch {
        // NAVIGIRAJ NEGDI
      }
    };

    const day = resDate.getDay(),
      diff = resDate.getDate() - day + (day === 0 ? -7 : 0);
    const firstday = new Date(resDate.setDate(diff));

    const dates = [];
    for (let i = 0; i < 7; i++) {
      dates.push(new Date(firstday.setDate(firstday.getDate() + 1)));
    }

    setDaysOfWeek(dates);
    loadTimeSlots();
  }, [reservation, i18n.language]);

  useEffect(
    () => () => {
      onReservationChange({ startDate: selectedDate.current });
    },
    [onReservationChange]
  );

  return (
    <div className="flex-direction-column">
      <h1 className="large-p">{t("TimeStep.Title")}</h1>

      <div className="page">
        <div className="wrapper">
          <div className="react-calendar">
            <div className="react-calendar__navigation">
              <button
                aria-label=""
                className="react-calendar__navigation__label flex-grow-1"
                type="button"
              >
                <span className="react-calendar__navigation__label__labelText react-calendar__navigation__label__labelText--from">
                  {targetMonthName} {targetYear}
                </span>
              </button>
            </div>
            <div className="react-calendar__viewContainer">
              <div className="react-calendar__month-view">
                <div className="flex-align-left">
                  <div className="flex-glow-width-100">
                    <div className="react-calendar__month-view__weekdays flex-no-wrap">
                      <CalendarDay
                        name={t("Calendar.Days.Monday")}
                        shortName={t("Calendar.DaysShort.Monday")}
                      />
                      <CalendarDay
                        name={t("Calendar.Days.Tuesday")}
                        shortName={t("Calendar.DaysShort.Tuesday")}
                      />
                      <CalendarDay
                        name={t("Calendar.Days.Wednesday")}
                        shortName={t("Calendar.DaysShort.Wednesday")}
                      />
                      <CalendarDay
                        name={t("Calendar.Days.Thursday")}
                        shortName={t("Calendar.DaysShort.Thursday")}
                      />
                      <CalendarDay
                        name={t("Calendar.Days.Friday")}
                        shortName={t("Calendar.DaysShort.Friday")}
                      />
                      <CalendarDay
                        name={t("Calendar.Days.Saturday")}
                        shortName={t("Calendar.DaysShort.Saturday")}
                      />
                      <CalendarDay
                        name={t("Calendar.Days.Sunday")}
                        shortName={t("Calendar.DaysShort.Sunday")}
                      />
                    </div>

                    <div className="react-calendar__month-view__days flex-wrap">
                      {daysOfWeek.map((day) => (
                        <button
                          type="button"
                          key={day.getDate()}
                          style={
                            day.getDate() === targetDayInMonth
                              ? {
                                  backgroundColor: "#314D69",
                                  color: "white",
                                  borderRadius: "10px",
                                }
                              : {}
                          }
                          className="react-calendar__tile react-calendar__month-view__days__day react-calendar-flex"
                          disabled={
                            currentDate > day || day > maxDate ? true : false
                          }
                          value={day}
                          onClick={handleDateChange}
                        >
                          {day.getDate()}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr />
          {timeSlots.length !== 0 ? (
            <div>
              {activities.map(activity => {
                return (
                  <>
                    <h3 style={{textAlign: 'center', color: '#173655'}}>{activity.name}</h3>
                    <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                    {timeSlots.some(slot => slot.locationActivityId === activity.id) ? (
                        timeSlots
                          .filter(slot => slot.locationActivityId === activity.id)
                          .map((timeslot) => {
                          return (
                            <>
                              <OptionPicker
                                key={timeslot.start}
                                name="timeslot"
                                identity={timeslot.start}
                                activity={timeslot.locationActivityId}
                                text={
                                  timeslot.end === null
                                    ? timeslot.start.substring(0, 5)
                                    : timeslot.start.substring(0, 5) +
                                      " - " +
                                      timeslot.end.substring(0, 5)
                                }
                                handleChange={handleTimeSlotChange}
                              />
                            </>
                          );
                        })
                    ) : (
                      <p style={{marginTop: '-5px', color: '#173655'}}>No timeslots to display for {activity.name}</p>
                    )}
                    
                  
                    <hr style={{width: '300px'}} />
                    </div>
                  </>
                )
              })}

              {notes.length > 0
                ? notes.map((note) => (
                    <p
                      style={{
                        marginTop: "2em",
                        textAlign: "center",
                        color: "#314D69",
                        fontWeight: "bold",
                        width: '350px'
                      }}
                    >
                      {note}
                    </p>
                  ))
                : null}
            </div>
          ) : (
            <p
              style={{
                textAlign: "center",
                fontSize: "0.9em",
                marginBottom: "1em",
              }}
            >
              {timeSlots === "" ? "Loading..." : null}

              {timeSlots !== ""
                ? t("TimeStep.NoTimes", {
                    date: formatToDayMonthYear(
                      selectedDate.current,
                      i18n.language
                    ),
                    location: locationName,
                  })
                : null}

              {timeSlots !== "" && hasCompanyOtherLocations ? (
                <Link
                  style={{ cursor: "pointer", color: "#314D69" }}
                  to={`/${companySlug}`}
                >
                  {t("TimeStep.DontWorrySeeOtherLocations")}
                </Link>
              ) : null}
            </p>
          )}

          {hasCompanyOtherLocations && timeSlots.length !== 0 ? (
            <button
              onClick={() => navigate(`/${companySlug}`)}
              style={{
                color: "#314D69",
                cursor: "pointer",
                border: 0,
                background: "transparent",
                outline: "none",
                marginTop: "1em",
                fontSize: "0.9em",
              }}
            >
              {t("TimeStep.OtherLocations")}
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
}
