export default function OptionPicker({identity, text, name, handleChange, description, activity}) {
    return (
        <label key={identity} htmlFor={identity}>
            <input
                name={name}
                id={identity}
                value={identity}
                onClick={handleChange}
                data-activity = {activity}
                type="radio"
                className="peer radio"
            />
            <div className="icon">
                <label htmlFor={name} style={{ pointerEvents: "none"}}>
                    {text}
                </label>
            </div>
        </label>
    );
}