export const USER_DATA_STEP = 1;
export const UNITS_STEP = 2;
export const ACTIVITIES_STEP = 3;
export const NUMBER_OF_GUESTS_STEP = 4;
export const DATE_STEP = 5;
export const TIME_STEP = 6;
export const FINAL_STEP = 7;
export const ROOMS_STEP = 8;
export const QUESTIONNAIRE_STEP = 9;
export const POLICY_STEP = 10;
export const TIME_BY_ACTIVITY_STEP = 11;